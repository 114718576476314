import React from "react";

export default function Loading({ isLoading, pastDelay, error }) {
  if (isLoading && pastDelay) {
    return <span />;
  } else if (error && !isLoading) {
    return <span />;
  } else {
    return null;
  }
}
