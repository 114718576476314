import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import carlyHead from "../images/carlyhead.png";
import charlieHead from "../images/charliehead.png";
import tavieHead from "../images/tavie.png";
import map from "../images/map.png";
import "./layout.css";

const ListLink = (props) => (
  <li>
    <Link
      style={{
        textDecoration: `none`,
        color: `rgba(0,0,0,0.8)`,
      }}
      to={props.to}
    >
      <span
        style={{
          fontFamily: "Reem Kufi",
          margin: `10px 0`,
          fontSize: `0.75rem`,
        }}
      >
        {props.children}
      </span>
    </Link>
    {props.showDot && <span>&nbsp;&middot;&nbsp;</span>}
  </li>
);

export default ({ bgColor, children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                title
                color
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            maxWidth: `975px`,
            margin: `0 auto`,
            position: `relative`,
          }}
        >
          <header style={{ textAlign: `center`, marginBottom: `0px` }}>
            <img
              className={"head"}
              src={carlyHead}
              alt="carly"
              style={{
                width: `200px`,
              }}
            />
            <img
              className={"head charlie"}
              src={charlieHead}
              alt="charlie"
              style={{
                width: `220px`,
              }}
            />
            <img
              className={"head charlie"}
              src={tavieHead}
              alt="tavie"
              style={{
                width: `220px`,
              }}
            />
          </header>
          <div
            style={{
              display: `flex`,
              flexDirection: `row`,
              alignItems: `space-between`,
              justifyContent: `center`,
              position: `sticky`,
            }}
          ></div>
          <main style={{ flexGrow: `1`, padding: `0 20px` }}>{children}</main>
        </div>
      );
    }}
  />
);
