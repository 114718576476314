import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import favicon from "../images/favicon.ico";
import Loadable from "react-loadable";
import Loading from "./loading";
import Img from "gatsby-image";
import "./style.css";

const LoadableComponent = Loadable({
  loader: () => import("./p5sketch.js"),
  loading: Loading
});

export default ({ data }) => {
  const page = data.markdownRemark;
  const pics = data.allImageSharp;
  const photos = pics.edges
    .filter(e => {
      return e.node.fluid.src.includes("ccc");
    })
    .sort((a, b) => {
      if (a.node.fluid.src > b.node.fluid.src) return 1;
      if (a.node.fluid.src < b.node.fluid.src) return -1;
      return 0;
    })
    .map(e => e.node.fluid);
  const front = 2;
  return (
    <div>
      <LoadableComponent />
      <Layout bgColor={page.frontmatter.color}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Carly and Charlie</title>
          <link rel="canonical" href="https://carlyandcharlie.com" />
          <link rel="shortcut icon" href={favicon} />
        </Helmet>
        <h1
          style={{
            textAlign: `center`
          }}
        >
          {page.frontmatter.title.toUpperCase() !== "EVENTS" &&
            page.frontmatter.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: page.html }}
          style={{
            height: `100%`,
            textAlign: `left`
          }}
        />
        {page.frontmatter.title === "Us" &&
          photos.map((p, i) => {
            if (i === front) {
              return <span />;
            }
            return (
              <div key={i} style={{ margin: "10px 0" }}>
                <Img fluid={photos[i]} alt="" />
              </div>
            );
          })}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        color
      }
    }
    allImageSharp {
      edges {
        node {
          id
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
